import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  let menuType = "primary"
  if (typeof window !== "undefined") {
    menuType = window.location.pathname.includes("przedszkole")
      ? "kindergarden"
      : "primary"
  }

  return (
    <Layout menuType={menuType}>
      <SEO title="404: Nie znaleziono - ZSP Nidek" />
      <h1>404: Nie znaleziono</h1>
      <p>Podana strona nie istnieje.</p>
    </Layout>
  )
}

export default NotFoundPage
